import { createGetKcContext } from "keycloakify/login";

export const { getKcContext } = createGetKcContext();

export const { kcContext } = getKcContext({
    // mockPageId: "login.ftl",
    // mockPageId: "login-reset-password.ftl",
    // mockPageId: "login-update-password.ftl",
});


export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;